import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 1.3em;
`;

const Summary = ({ cart, className }) => (
  <Wrapper className={className}>
    <strong>Итого,</strong> без учёта доставки,{' '}
    <strong>
      {cart.number} товар(ов/а) на сумму {cart.total.toFixed(2)} ₽
    </strong>
  </Wrapper>
);

export default Summary;
